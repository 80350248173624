<template>
  <button
    :class="['play-button', `play-button-${size}`]"
    @click="$emit('click', $event)"
  >
    <span class="at-text-wrap">
      <span class="at-text">
        {{ isPlaying ? "Pause the Video" : "Play the Video" }}
      </span>
    </span>
    <SvgIcon v-if="!isPlaying" icon="play" aria-hidden="true" class="icon" />
    <SvgIcon v-if="isPlaying" icon="pause" aria-hidden="true" class="icon" />
  </button>
</template>

<script>
  export default {
    props: {
      isPlaying: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: "medium",
        validator: (value) => {
          return ["medium", "small"].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "assets/styles";

  .play-button {
    background: $blue;
    border: none;
    width: 12.5%;
    max-width: 128px;
    min-width: 44px;
    aspect-ratio: 1;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-small {
      width: 6.25%;
    }
  }
  .at-text-wrap {
    position: relative;
  }
  .at-text {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    user-select: none;
    font-size: 1px;
  }
  .icon {
    width: 60%;
    height: 60%;
    color: white;
    display: block;
  }
</style>
